.install-prompt {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;  
    color: rgb(0, 0, 0); 
    text-align: center;
    padding: 10px 20px;
    font-size: 16px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 10px;  
    border-top-right-radius: 10px;
    z-index: 1000;  
}

.install-prompt p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.install-prompt img {
    vertical-align: middle;
    margin-right: 5px;
}