@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.page-container {
    padding-top: 50px;
  }


.outer {
    
  margin: 15px;
  width: 295px;
  height: 228px;
  border-radius: 40px;
  background: #fff;
  border: 1px solid #f0f0f0;
  box-shadow:
  0 3.4px 2.7px rgba(0, 0, 0, 0.022),
  0 8.7px 6.9px rgba(0, 0, 0, 0.031),
  0 17.7px 14.2px rgba(0, 0, 0, 0.039),
  0 36.5px 29.2px rgba(0, 0, 0, 0.048),
  0 100px 80px rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.inner {
  width: 325px;
  height: 70%;
  background: url('https://images.unsplash.com/photo-1513104890138-7c749659a591?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80') no-repeat center center/cover;
  object-fit: cover;
  border: 1px solid #f0f0f0;
  border-bottom-right-radius: 80px;
  transform: translateX(-4px) translateY(-17px) rotate(10deg);
}

.item {
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 26px;
}

.item-name h4 {
  font-size: 18px;
  font-weight: bold;
  color: #11313c;
}

.item-name p {
  font-size: 12px;
  font-weight: 500;
  color: #949a98;
}

.item-price {
  display: flex;
  flex-direction: column;
}

.item-price p {
  font-size: 18px;
  font-weight: bold;
  color: #e30613;
  text-align: right;
}

.rating {
  display: flex;
}

.rating > div {
  width: 15px;
  height: 15px;
  background: #f7736e;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}

.outer:hover {
  transform: scale(1.01);
}