.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pretty-underline {
  position: relative;
  display: inline; 
  font-size: 29px;
  font-family: 'Arial', sans-serif; 
  padding: 0 2px; 
}

.pretty-underline::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px; 
  height: 10px; 
  background: linear-gradient(to right, pink, red); 
  z-index: -1;
}